<template>
  <div class="main">
    <NavBar />
    <!-- Loading -->
    <div v-if="loading" class="content">
      <div class="loader m-auto"></div>
    </div>

    <!-- Content -->
    <div v-else class="content">
      <!-- Header -->
      <div class="row">
        <p class="project-header">
          {{ !translate ? 'รายละเอียดโครงงาน ' + project.topic_th : project.topic_th }}<br>
          <span class="whitespace-pre-line text-lg">({{ project.topic_en }})</span>
        </p>
        <div class="ml-auto">
          <router-link v-if="can_edit" :to="'/project/' + this.$route.params.pid + '/edit/'" class="mr-4">
            <button class="edit-button">{{ !translate ? 'แก้ไขข้อมูล' : 'Edit project' }}</button>
          </router-link>
          <button v-if="role === 'STF'" class="delete-button" v-on:click="deleteProject(project)">{{ !translate ? 'ลบข้อมูล' : 'Delete project' }}</button>
          <button v-if="role === 'STD'" v-on:click="translate = !translate" class="language-button">{{ !translate ? 'EN' : 'TH' }}</button>
        </div>
      </div>
      
      <!-- Program & Category -->
      <div class="row">
        <div class="program-button" style="background-color: rgb(19 120 33)">{{ course.type_name }}</div>
        <div class="program-button" style="background-color: rgb(59 130 246)">{{ course.program_name }}</div>
        <div class="program-button" style="background-color: rgb(220 38 38)">{{ project.category_detail.name }}</div>
        <div v-if="course.year >= 2565 && course.type != 'IS'" class="flex flex-row ml-auto">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7 my-auto mr-4" fill="none" viewBox="0 0 24 24" stroke="black"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"/></svg>
          <div @click="downloadFile()" class="program-button cursor-pointer" style="background-color: #17a2b8">{{ !translate ? 'แบบเสนอหัวข้อโครงงาน (ยังไม่เซ็น)' : 'Project proposal form (Not signed)' }}</div>
        </div>
      </div>

      <!-- Keyword -->
      <div>
        <p class="label">{{ !translate ? 'คำสำคัญ' : 'Project keywords' }}</p>
        <div class="row">
          <div v-for="keyword in keywords" :key="keyword">
            <div class="keyword-button">{{ keyword }}</div>
          </div>
        </div>
      </div>
      <hr>
      
      <!-- Student -->
      <p class="label mb-4">{{ !translate ? 'นักศึกษาผู้รับผิดชอบ' : 'Project members' }}</p>
      <div v-for="(member, index) in project.member_details" :key="member.id" class="row">
        <p class="font-normal name-space pl-4">{{ member.name }}</p>
        <p v-if="index === 0" class="label">{{ !translate ? 'รหัสนักศึกษา' : 'ID' }}</p>
        <p v-else-if="index === 1" class="label"></p>
        <p class="font-normal">{{ member.code }}</p>
      </div>

      <!-- Advisor -->
      <div v-for="(advisor, index) in project.advisor_details" :key="advisor.id">
        <p v-if="(project.advisor_details.length === 1) && (index === 0)" class="row my-4">
          <span class="label">{{ !translate && project.course_details.type == 'CP' ? 'อาจารย์ที่ปรึกษา (นิเทศ)' : !translate && project.course_details.type != 'CP' ? 'อาจารย์ที่ปรึกษา' : 'Advisor' }}</span>
          <span class="font-normal advisor-name-space">{{ project.advisor_details[0].name }}</span>
          <span class="sm:-ml-8 xl:ml-0 label">{{ !translate ? 'อาจารย์ที่ปรึกษาร่วม' : 'Co-advisor' }}</span>
          <span class="font-normal">-</span>
        </p>
        <p v-if="(project.advisor_details.length === 2) && (index === 0)" class="row my-4">
          <span class="label">{{ !translate && project.course_details.type == 'CP' ? 'อาจารย์ที่ปรึกษา (นิเทศ)' : !translate && project.course_details.type != 'CP' ? 'อาจารย์ที่ปรึกษา' : 'Advisor' }}</span>
          <span class="font-normal advisor-name-space">{{ project.advisor_details[0].name }}</span>
          <span class="sm:-ml-8 xl:ml-0 label">{{ !translate ? 'อาจารย์ที่ปรึกษาร่วม' : 'Co-advisor' }}</span>
          <span class="font-normal">{{ project.advisor_details[1].name }}</span>
        </p>
      </div>

      <!-- Coop company --> 
      <div v-if="project.course_details.type == 'CP'" class="my-2">
        <div class="row my-4">
          <p class="label">{{ !translate ? 'บริษัท' : 'Name of establishment' }}</p>
          <span class="font-normal">{{ project.company.name != '' ? project.company.name : '-' }}</span>
        </div>
        <div class="row my-8">
          <p class="label">{{ !translate ? 'พนักงานที่ปรึกษา' : 'Job supervisor full name' }}</p>
          <span class="font-normal advisor-name-space">{{ project.company.advisor != '' ? project.company.advisor : '-' }}</span>
          <p class="sm:-ml-8 xl:ml-0 label">{{ !translate ? 'อีเมล' : 'Job supervisor E-mail' }}</p>
          <span class="font-normal">{{ project.company.email != '' ? project.company.email : '-' }}</span>
        </div>
      </div>

      <!-- Objective -->
      <div class="row my-4">
        <p v-if="project.course_details.type === 'IS'" class="label">{{ !translate ? 'จุดประสงค์' : 'Objectives' }}</p>
        <p v-else class="label">{{ !translate ? 'วัตถุประสงค์' : 'Objectives' }}</p>
        <span class="project-content">{{ project.objective }}</span>
      </div>

      <!-- Background -->
      <div v-if="project.course_details.type != 'IS' && project.background != ''" class="row my-4">
        <p class="label">{{ !translate ? 'ที่มาและความสำคัญ' : 'Background and significance' }}</p>
        <span class="project-content">{{ project.background }}</span>
      </div>

      <!-- Process -->
      <div class="row my-4">
        <p v-if="project.course_details.type === 'IS'" class="label">{{ !translate ? 'แนวทางการศึกษา' : 'Methodology' }}</p>
        <p v-else class="label">{{ !translate ? 'วิธีการดำเนินงาน' : 'Methodology' }}</p>
        <span class="project-content">{{ project.process }}</span>
      </div>

      <!-- Scope -->
      <div v-if="project.course_details.type != 'IS'" class="row my-4">
        <p class="label">{{ !translate ? 'ขอบเขตของโครงงาน' : 'Scope' }}</p>
        <span class="project-content">{{ project.scope }}</span>
      </div>

      <!-- Benefit -->
      <div class="row my-4">
        <p v-if="project.course_details.type === 'IS'" class="label">{{ !translate ? 'ผลที่คาดว่าจะได้รับ' : 'Expected benefits' }}</p>
        <p v-else class="label">{{ !translate ? 'ประโยชน์ที่คาดว่าจะได้รับ' : 'Expected benefits' }}</p>
        <span class="project-content">{{ project.benefit }}</span>
      </div>

      <!-- IS Category -->
      <div v-if="project.course_details.type == 'IS'" class="my-2">
        <div class="row my-4">
          <p class="label">ประเภทของโครงงาน</p>
          <ul class="font-normal">
            <li v-for="(category, index) in project.is_category" :key="category.id">
              <p v-if="category && index == 'compare'">- การศึกษาวิเคราะห์เชิงเปรียบเทียบตามหลักวิชาการ โดยจะต้องศึกษาและวิเคราะห์ในประเด็นที่ไม่เคยมีผู้ใดทำมาก่อน</p>
              <p v-else-if="category && index == 'casestudy'">- กรณีศึกษา โดยการประยุกต์ใช้ทฤษฎีตามหลักวิชาการเพื่อหาคำตอบหรือวิธีการแก้ไขปัญหาที่ชัดเจน</p>
              <p v-else-if="category && index == 'analysis'">- การวิเคราะห์และออกแบบโปรแกรมหรือระบบสารสนเทศ ควรประกอบด้วย Feasibility Study, Requirement Analysis, System Analysis, System Design</p>
              <p v-else-if="category && index == 'develop'">- การพัฒนาระบบหรือโปรแกรมถึงในระดับ Implementation โดยครอบคลุมการวิเคราะห์และออกแบบระบบ</p>
            </li>
          </ul>
        </div>
        <div class="row mt-8 mb-4">
          <p class="label">นักศึกษาใช้ผลงานในหน่วยงานของตน</p>
          <p class="font-normal">{{ project.is_category.agency ? "ใช้" : "ไม่ใช้" }}</p>
        </div>
      </div>
    </div>

    <!-- Document -->
    <div v-if="!loading" class="content">
      <div class="row">
        <p class="label">{{ !translate ? 'ไฟล์และเอกสารของโครงงาน' : 'Project documents' }}</p> 
      </div>
      <!-- Old Document -->
      <div v-for="(document, key) in project.old_project_details" :key="document">
        <div v-if="document" class="grid grid-cols-3 mb-2">
          <div class="indent col-span-2">{{ document[0] }}</div>
          <div class="grid grid-cols-2 ml-auto">
            <router-link v-if="document[0] != 'วิดีโอนำเสนอ'" :to="`/form/${key}/old_project/${this.$route.params.pid}`" class="view-form-button">{{ !translate ? 'ดูรายละเอียด' : 'View' }}</router-link>
            <a v-else :href="document[1]" target="_blank" class="view-form-button">{{ !translate ? 'ดูรายละเอียด' : 'View' }}</a>
          </div>
        </div>
      </div>

      <!-- Document (Current system) -->
      <div v-for="form in filter_forms" :key="form.id">
        <div class="grid grid-cols-3 my-4">
          <!-- Form title -->
          <div class="indent col-span-2">
            <p>{{ form.form_entry_detail.title }}</p>
            <small v-if="form.notification_status == true && form.role == 'CMP'" class="ml-4">(ส่งการแจ้งเตือนถึงพนักงานที่ปรึกษาแล้ว)</small>
            <small v-if="form.notification_status == false && form.role == 'CMP'" class="ml-4">(ยังไม่ส่งการแจ้งเตือนถึงพนักงานที่ปรึกษา)</small>
          </div>

          <!-- Form document -->
          <div v-if="form.multiple" class="grid grid-cols-2 ml-auto">
            <router-link v-if="(form.role === role && !form.expired && role == 'STD') || (form.role === role && !form.expired && role == 'TCH' && is_advisor)" :to="`/form/${form.id}/project/${this.$route.params.pid}/edit/`" class="form-button">{{ !translate ? 'กรอกฟอร์ม' : 'Fill in' }}</router-link>
            <div v-else-if="form.details.length == 0" class="none-form-button">{{ !translate ? 'ไม่พบข้อมูล' : 'Not found' }}</div>
          </div>
          <div v-else class="grid grid-cols-2 ml-auto">
            <!-- Coop -->
            <a v-if="!form.expired && form.exam_type != null && form.role == role && is_advisor" :href="'https://boron.it.kmitl.ac.th/project-exam/score/' + form.exam_type + '/' + project.uuid" target="_blank" class="view-form-button">{{ !translate ? 'ดูรายละเอียด' : 'View' }}</a>
            <a v-else-if="(form.expired && form.exam_type != null && form.form_entry == null && is_advisor) || (form.exam_type != null && form.form_entry == null && !is_advisor) || (form.role == 'CMP' && form.exam_type != null && form.form_entry == null) || (form.role == 'TCH' && role != 'TCH' && form.exam_type != null && form.form_entry == null)" target="_blank" class="none-form-button">{{ !translate ? 'ไม่สามารถดูได้' : 'Can\'t View' }}</a>
            
            <!-- Student & Teacher -->
            <router-link v-else-if="(form.role === role && form.details[0].status === false && !form.expired && role == 'STD') || (form.role === role && form.details[0].status === false && !form.expired && role == 'TCH' && is_advisor)" :to="`/form/${form.id}/project/${this.$route.params.pid}/edit/`" class="form-button">{{ !translate ? 'กรอกฟอร์ม' : 'Fill in' }}</router-link>
            <router-link v-else-if="form.role === role && form.details[0].status === 'F' && !form.expired" :to="`/form/${form.id}/project/${this.$route.params.pid}/edit/`" class="fail-form-button">{{ !translate ? 'แก้ไขฟอร์ม' : 'Edit' }}</router-link>
            <router-link v-else-if="form.role === role && form.details[0].status != false" :to="`/form/${form.id}/project/${this.$route.params.pid}/`" class="view-form-button">{{ !translate ? 'ดูรายละเอียด' : 'View' }}</router-link>
            
            <!-- Teacher -->
            <router-link v-else-if="is_advisor && (form.details[0].status === 'P' | form.details[0].status === 'F')" :to="`/form/${form.id}/project/${this.$route.params.pid}/`" class="view-form-button">{{ !translate ? 'ดูรายละเอียด' : 'View' }}</router-link>
            <router-link v-else-if="is_advisor && form.details[0].status === 'W' && !form.expired" :to="`/form/${form.id}/project/${this.$route.params.pid}/`" class="form-button">{{ !translate ? 'ตรวจสอบฟอร์ม' : 'Verify' }}</router-link>
            
            <!-- Staff -->
            <div v-else-if="form.details[0].status === false" class="none-form-button">{{ !translate ? 'ไม่พบข้อมูล' : 'Not found' }}</div>
            <router-link v-else :to="`/form/${form.id}/project/${this.$route.params.pid}/`" class="view-form-button">{{ !translate ? 'ดูรายละเอียด' : 'View' }}</router-link>
            
            <!-- Status -->
            <img v-if="form.details[0].status === 'W'" src="@/assets/status-w.png" class="h-8 my-auto ml-3"/>
            <img v-else-if="form.details[0].status === 'P'" src="@/assets/status-p.png" class="h-8 my-auto ml-3"/>
            <img v-else-if="form.details[0].status === 'F'" src="@/assets/status-f.png" class="h-8 my-auto ml-3"/>
            <small v-if="project.course_details.type == 'CP' && role == 'TCH' && form.exam_type != null && form.role == 'CMP' && is_advisor && !form.expired" class="cursor-pointer my-auto ml-4 col-1" style="color: blue">
              <u @click="notification_coop(form)">แจ้งเตือนอีกครั้ง</u>
            </small>
          </div>
        </div>

        <!-- Form document (Multiple) -->
        <div v-if="form.multiple">
          <div v-for="(detail, index) in form.details" :key="index" class="grid grid-cols-4 mb-2">
            <div class="ml-8 font-normal my-2 col-span-3">{{ !translate ? 'ครั้งที่' : 'No.' }} {{ (index + 1) }} <span class="italic text-gray-500 font-light">( สร้างเมื่อวันที่ {{ detail.created }} )</span></div>
            <div class="grid grid-cols-3 ml-6">
              <!-- Student & Teacher -->
              <router-link v-if="form.role === role && detail.status === 'F' && !form.expired" :to="`/form/${form.id}/project/${this.$route.params.pid}/${index+1}/edit/`" class="fail-form-button">{{ !translate ? 'แก้ไขฟอร์ม' : 'Edit' }}</router-link>
              <router-link v-else-if="form.role === role && detail.status != false" :to="`/form/${form.id}/project/${this.$route.params.pid}/${index+1}/`" class="view-form-button">{{ !translate ? 'ดูรายละเอียด' : 'View' }}</router-link>
              
              <!-- Teacher -->
              <router-link v-else-if="is_advisor && (detail.status === 'P' | detail.status === 'F')" :to="`/form/${form.id}/project/${this.$route.params.pid}/${index+1}/`" class="view-form-button">{{ !translate ? 'ดูรายละเอียด' : 'View' }}</router-link>
              <router-link v-else-if="is_advisor && detail.status === 'W' && !form.expired" :to="`/form/${form.id}/project/${this.$route.params.pid}/${index+1}/`" class="form-button">{{ !translate ? 'ตรวจสอบฟอร์ม' : 'Verify' }}</router-link>
              
              <!-- Staff -->
              <div v-else-if="detail.status === false" class="none-form-button">{{ !translate ? 'ไม่พบข้อมูล' : 'Not found' }}</div>
              <router-link v-else :to="`/form/${form.id}/project/${this.$route.params.pid}/${index+1}/`" class="view-form-button">{{ !translate ? 'ดูรายละเอียด' : 'View' }}</router-link>
              
              <!-- Status -->
              <img v-if="detail.status === 'W'" src="@/assets/status-w.png" class="h-8 my-auto ml-auto"/>
              <img v-else-if="detail.status === 'P'" src="@/assets/status-p.png" class="h-8 my-auto ml-auto"/>
              <img v-else-if="detail.status === 'F'" src="@/assets/status-f.png" class="h-8 my-auto ml-auto"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Document (PROJECT 2) -->
    <div v-if="project.course_details.type === 'PJ' && filter_next_forms.length > 0 && !loading" class="content" >
      <div class="row">
        <p class="label">{{ !translate ? 'ไฟล์และเอกสารของโครงงาน 2' : 'Project 2 documents' }}</p> 
      </div>
      <div v-for="form in filter_next_forms" :key="form.id">
        <div class="grid grid-cols-3 my-4">
          <!-- Form title -->
          <div class="indent col-span-2">
            <p>{{ form.form_entry_detail.title }}</p>
          </div>

          <!-- Form document -->
          <div v-if="form.multiple" class="grid grid-cols-2 ml-auto">
            <router-link v-if="(form.role === role && !form.expired && role == 'STD') || (form.role === role && !form.expired && role == 'TCH' && is_advisor)" :to="`/form/${form.id}/project/${this.$route.params.pid}/edit/`" class="form-button">{{ !translate ? 'กรอกฟอร์ม' : 'Fill in' }}</router-link>
            <div v-else-if="form.details.length == 0" class="none-form-button">{{ !translate ? 'ไม่พบข้อมูล' : 'Not found' }}</div>
          </div>
          <div v-else class="grid grid-cols-2 ml-auto">
            <!-- Student & Teacher -->
            <router-link v-if="(form.role === role && form.details[0].status === false && !form.expired && role == 'STD') || (form.role === role && form.details[0].status === false && !form.expired && role == 'TCH' && is_advisor)" :to="`/form/${form.id}/project/${this.$route.params.pid}/edit/`" class="form-button">{{ !translate ? 'กรอกฟอร์ม' : 'Fill in' }}</router-link>
            <router-link v-else-if="form.role === role && form.details[0].status === 'F' && !form.expired" :to="`/form/${form.id}/project/${this.$route.params.pid}/edit/`" class="fail-form-button">{{ !translate ? 'แก้ไขฟอร์ม' : 'Edit' }}</router-link>
            <router-link v-else-if="form.role === role && form.details[0].status != false" :to="`/form/${form.id}/project/${this.$route.params.pid}/`" class="view-form-button">{{ !translate ? 'ดูรายละเอียด' : 'View' }}</router-link>
            
            <!-- Teacher -->
            <router-link v-else-if="is_advisor && (form.details[0].status === 'P' | form.details[0].status === 'F')" :to="`/form/${form.id}/project/${this.$route.params.pid}/`" class="view-form-button">{{ !translate ? 'ดูรายละเอียด' : 'View' }}</router-link>
            <router-link v-else-if="is_advisor && form.details[0].status === 'W' && !form.expired" :to="`/form/${form.id}/project/${this.$route.params.pid}/`" class="form-button">{{ !translate ? 'ตรวจสอบฟอร์ม' : 'Verify' }}</router-link>
            
            <!-- Staff -->
            <div v-else-if="form.details[0].status === false" class="none-form-button">{{ !translate ? 'ไม่พบข้อมูล' : 'Not found' }}</div>
            <router-link v-else :to="`/form/${form.id}/project/${this.$route.params.pid}/`" class="view-form-button">{{ !translate ? 'ดูรายละเอียด' : 'View' }}</router-link>
            
            <!-- Status -->
            <img v-if="form.details[0].status === 'W'" src="@/assets/status-w.png" class="h-8 my-auto ml-3"/>
            <img v-else-if="form.details[0].status === 'P'" src="@/assets/status-p.png" class="h-8 my-auto ml-3"/>
            <img v-else-if="form.details[0].status === 'F'" src="@/assets/status-f.png" class="h-8 my-auto ml-3"/>
          </div>
        </div>
        
        <!-- Form document (Multiple) -->
        <div v-if="form.multiple">
          <div v-for="(detail, index) in form.details" :key="index" class="grid grid-cols-4 mb-2">
            <div class="ml-8 font-normal my-2 col-span-3">{{ !translate ? 'ครั้งที่' : 'No.' }} {{ (index + 1) }} <span class="italic text-gray-500 font-light">( สร้างเมื่อวันที่ {{ detail.created }} )</span></div>
            <div class="grid grid-cols-3 ml-6">
              <!-- Student & Teacher -->
              <router-link v-if="form.role === role && detail.status === 'F' && !form.expired" :to="`/form/${form.id}/project/${this.$route.params.pid}/${index+1}/edit/`" class="sm:-ml-8 xl:ml-0 fail-form-button">{{ !translate ? 'แก้ไขฟอร์ม' : 'Edit' }}</router-link>
              <router-link v-else-if="form.role === role && detail.status != false" :to="`/form/${form.id}/project/${this.$route.params.pid}/${index+1}/`" class="sm:-ml-8 xl:ml-0 view-form-button">{{ !translate ? 'ดูรายละเอียด' : 'View' }}</router-link>
              
              <!-- Teacher -->
              <router-link v-else-if="is_advisor && (detail.status === 'P' | detail.status === 'F')" :to="`/form/${form.id}/project/${this.$route.params.pid}/${index+1}/`" class="sm:-ml-8 xl:ml-0 view-form-button">{{ !translate ? 'ดูรายละเอียด' : 'View' }}</router-link>
              <router-link v-else-if="is_advisor && detail.status === 'W' && !form.expired" :to="`/form/${form.id}/project/${this.$route.params.pid}/${index+1}/`" class="sm:-ml-8 xl:ml-0 form-button">{{ !translate ? 'ตรวจสอบฟอร์ม' : 'Verify' }}</router-link>
              
              <!-- Staff -->
              <div v-else-if="detail.status === false" class="sm:-ml-8 xl:ml-0 none-form-button">{{ !translate ? 'ไม่พบข้อมูล' : 'Not found' }}</div>
              <router-link v-else :to="`/form/${form.id}/project/${this.$route.params.pid}/${index+1}/`" class="sm:-ml-8 xl:ml-0 view-form-button">{{ !translate ? 'ดูรายละเอียด' : 'View' }}</router-link>
              
              <!-- Status -->
              <img v-if="detail.status === 'W'" src="@/assets/status-w.png" class="h-8 my-auto ml-auto"/>
              <img v-else-if="detail.status === 'P'" src="@/assets/status-p.png" class="h-8 my-auto ml-auto"/>
              <img v-else-if="detail.status === 'F'" src="@/assets/status-f.png" class="h-8 my-auto ml-auto"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar"
import moment from "moment"
import { changeTypeName } from "../mixins/other"

export default {
  name: "ProjectView",
  components: {
    NavBar,
  },
  data() {
    return {
      loading: true,
      project: {
        category_detail: { name: '' },
        course_details: { type: 'PJ' }
      },
      course: { program_name: '' },
      next_course: null,
      notification: null,
      keywords: [],
      advisor: [],
      role: null,
      is_advisor: false,
      can_edit: true,
      forms: [],
      next_forms: [],
      filter_forms: [],
      filter_next_forms: [],
      translate: false
    }
  },
  async created() {
    this.role = this.$store.getters.payload.role
    this.project = await this.$store.dispatch("getProjectById", this.$route.params.pid)
    this.course = await this.$store.dispatch("getCourseById", this.project.course)
    this.course.type_name = changeTypeName(this.course, 'projectView')
    this.forms = await this.$store.dispatch("getFormByCourseId", { id: this.project.course, type: "all" })
    this.keywords = this.project.keyword.split(/[,]/)
    this.next_course = await this.$store.dispatch("getNextCourse", this.project.course).catch((err) => console.log(err))
    document.title = this.project.topic_th + " | Carbon"

    // Check if next course exists (PROJECT 2)
    if (this.next_course) {
      this.next_forms = await this.$store.dispatch("getFormByCourseId", { id: this.next_course.id, type: "all" }).catch((err) => console.log(err))
    }

    // Permission
    if (this.project.members.includes(this.$store.getters.payload.user_id) == false && this.role == 'STD') {
      this.$router.push("/")
    }

    // Check if user is an advisor of this project
    if (this.project.advisors.includes(this.$store.getters.payload.user_id)) {
      this.is_advisor = true
    }

    // Check Coop notification
    if (this.course.type == 'CP') {
      this.coop_form = await this.$store.dispatch("getCoopForm", { course: this.course.code, semester: this.course.semester, year: this.course.year })
      this.notification = await this.$store.dispatch("getNotificationByProjectId", this.project.id)
    }

    // Check if user can edit this project
    if (new Date(this.course.form_active_date_from) > new Date() || new Date(this.course.form_active_date_to) < new Date()) {
      if (this.course.type != 'CP' && this.role != 'STF') {
        this.can_edit = false
      } else if (this.course.type == 'CP') {
        this.can_edit = true
      }
    }

    if (this.course.type == 'CP' && this.role == 'TCH' && this.project.advisors.includes(this.$store.getters.payload.user_id) == false) {
      this.can_edit = false
    }

    if (this.course.type != 'CP') {
      if (this.role != 'TCH' && new Date(this.course.form_active_date_from) < new Date() && new Date(this.course.form_active_date_to) > new Date()) {
        this.can_edit = true
      } else {
        this.can_edit = this.role == 'STF' ? true : false
      }
    }
      
    // Course form
    for (let i = 0; i < this.forms.length; i++) {
      let details = []
      let existed = true
      let amount = 0
      do {
        const formData = await this.$store.dispatch("getFormData", { form_id: this.forms[i].id, project_id: this.$route.params.pid, order_id: amount }).catch((error) => (existed = error))
        if (existed) {
          details.push({status: formData.status, created: moment(new Date(formData.created)).locale("th").add(543, 'year').format('LLLL'), datetime: new Date(formData.created)})
        } else {
          details.push({status: false, created: null, datetime: null})
        }
        amount = amount + 1
      } while (existed && this.forms[i].multiple)
      
      if (this.forms[i].multiple) {
        details.pop()
      }

      this.forms[i].details = details

      if (this.course.type == 'CP') {
        if (this.forms[i].exam_type != null && this.forms[i].form_entry == null) {
          for (let j in this.coop_form) { 
            if (this.coop_form[j]['exam_type_id'] == this.forms[i].exam_type) {
              this.forms[i].form_entry_detail.title = this.coop_form[j]['name']
            }
          }
        }
        
        if (this.forms[i].exam_type in this.notification && this.forms[i].exam_type != null) {
          this.forms[i].notification_status = this.notification[this.forms[i].exam_type]
        } else {
          this.forms[i].notification_status = null
        }
      }

      if ((this.forms[i].role == this.role) && (this.role == "STD") || this.role != "STD") {
        if (new Date(this.forms[i].active_date_from) > new Date() || new Date(this.forms[i].active_date_to) < new Date()) {
          this.forms[i].expired = true
        } else {
          this.forms[i].expired = false
        }
        this.filter_forms.push(this.forms[i])
      }
    }

    // Next course (PROJECT 2)
    for (let i = 0; i < this.next_forms.length; i++) {
      let details = []
      let existed = true
      let amount = 0
      do {
        const formData = await this.$store.dispatch("getFormData", { form_id: this.next_forms[i].id, project_id: this.$route.params.pid, order_id: amount }).catch((error) => (existed = error))
        if (existed) {
          details.push({status: formData.status, created: moment(new Date(formData.created)).locale("th").add(543, 'year').format('LLLL'), datetime: new Date(formData.created)})
        } else {
          details.push({status: false, created: null, datetime: null})
        }
        amount = amount + 1
      } while (existed && this.next_forms[i].multiple)
      
      if (this.next_forms[i].multiple) {
        details.pop()
      }

      this.next_forms[i].details = details

      if ((this.next_forms[i].role == this.role) && (this.role == "STD") || this.role != "STD") {
        if(new Date(this.next_forms[i].active_date_from) > new Date() || new Date(this.next_forms[i].active_date_to) < new Date()){
          this.next_forms[i].expired = true
        } else {
          this.next_forms[i].expired = false
        }
        this.filter_next_forms.push(this.next_forms[i])
      }
    }
    this.loading = false
  },
  methods: {
    async deleteProject() {
      if (confirm("ต้องการลบโครงงาน " + this.project.topic_th  + " หรือไม่") == true) {
        const success = await this.$store.dispatch("deleteProjectById", this.project.id)
        if(success) {
          alert("ลบโครงงานเสร็จสิ้น")
          this.$router.push("/project/")
        }
      }
    },
    async notification_coop(form) {
      if (this.project.company.email == null || this.project.company.email == '' || 
          this.project.company.advisor == null || this.project.company.advisor == '' || 
          this.project.company.name == null || this.project.company.name == '') {
        alert("กรุณากรอก ชื่อ-นามสกุลของพนักงานที่ปรึกษา อีเมล \nและชื่อบริษัทให้ครบถ้วน โดยสามารถกรอกได้ที่การกดปุ่ม \"แก้ไขข้อมูล\" \nบริเวณมุมขวาบนของหน้านี้")
      } else if (confirm("ต้องการแจ้งเตือนแบบฟอร์ม " + form.form_entry_detail.title + " สำหรับพนักงานที่ปรึกษาของโครงงาน " + this.project.topic_th  + " หรือไม่") == true) {
        const success = await this.$store.dispatch("notification", {project: this.project.id, noti_type: 'coop', form: form})
        if(success) {
          alert("แจ้งเตือนแบบฟอร์มสำหรับพนักงานที่ปรึกษาเสร็จสิ้น")
          location.reload()
        }
      }
    },
    async downloadFile() {
      await this.$store.dispatch("download101File", this.project.id)
    }
  }
}
</script>
